// Footer

.main__footer {
    grid-column-start: 2;
    background: linear-gradient(162deg,#55677f -11%,#3f1b38 104%);
    color: #ffffff;
    font-size: 0.8rem;
    font-weight: 600;
    
    nav {

    }

    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
    }

    li {
        margin: 0 5px;
    }

    p {
        margin: 0;
    }

    a {
        display: flex;
        align-items: center;
    }

    a, p {
        text-align: center;
        line-height: 12px;
    }
}