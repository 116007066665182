// Loader

.batiments-loader {
    .loader-version2.overlay {
        padding: 25px 15px;
        background-color: rgba(255,255,255,.9);
        //height: 100vh;
        height: auto;
        //position: fixed;
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1000;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .loader-img {
        width: 150px;
        height: auto;
        margin-bottom: 30px;
    }

    .wait {
        color: #5c5c5c;
        margin-bottom: 30px;
    }

    .loader-version2 .spinner-container {
        width: 60px;
        height: 60px;
        position: relative;
        margin: 0 auto;
    }

    .loader-version2 .spinner-container .spinner {
        display: block;
        position: relative;
        width: 60px;
        height: 60px;
        border-top-color: #c62222 !important;
        animation: spin 1.3s linear infinite;
    }
    
    .loader-version2 .spinner-container .spinner, .loader-version2 .spinner-container:after, .loader-version2 .spinner-container:before {
        border-radius: 50%;
        border: 1px solid transparent;
    }

    .loader-version2 .spinner-container:before {
        border-top-color: #2e404f !important;
        inset: 6px 6px 6px 6px;
        animation: spin .9s linear infinite;
    }
    
    .loader-version2 .spinner-container:after, .loader-version2 .spinner-container:before {
        content: "";
        position: absolute;
    }
    .loader-version2 .spinner-container .spinner, .loader-version2 .spinner-container:after, .loader-version2 .spinner-container:before {
        border-radius: 50%;
        border: 1px solid transparent;
    }

    .loader-version2 .spinner-container:after {
        border-top-color: #c62222 !important;
        inset: 12px 12px 12px 12px;
        animation: spin 1.1s linear infinite;
    }
    
    .loader-version2 .spinner-container:after, .loader-version2 .spinner-container:before {
        content: "";
        position: absolute;
    }
    .loader-version2 .spinner-container .spinner, .loader-version2 .spinner-container:after, .loader-version2 .spinner-container:before {
        border-radius: 50%;
        border: 1px solid transparent;
    }
    
    .loader-version2 .spinner-container .spinner {
        display: block;
        position: relative;
        width: 60px;
        height: 60px;
        border-top-color: #c62222 !important;
        animation: spin 1.3s linear infinite;
    }
    
    .loader-version2 .spinner-container .spinner, .loader-version2 .spinner-container:after, .loader-version2 .spinner-container:before {
        border-radius: 50%;
        border: 1px solid transparent;
    }
    

    @keyframes spin {
        0% {
            transform: rotate(0);
        }
        
        100% {
            transform: rotate(360deg);
        }
    }

}