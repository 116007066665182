.leaflet-legend {
    box-shadow: 0 1px 5px rgba(0,0,0,0.4);
    background: #fff;
    border-radius: 5px;
}

a.leaflet-legend-toggle {
    width: 36px;
    height: 36px;
}
.leaflet-touch .leaflet-legend-toggle {
    width: 44px;
    height: 44px;
}

.leaflet-legend  .leaflet-legend-list,
.leaflet-legend-expanded .leaflet-legend-toggle {
    display: none;
}

.leaflet-legend-expanded .leaflet-legend-list {
    display: block;
    position: relative;
    padding: 0;
    &:not(:first-of-type){
        margin-top: 15px;
    }
}

a.leaflet-legend-toggle {
    color: #000 !important;
    text-decoration: none;
    padding: 6px;
}
a.leaflet-legend-toggle i {
    font-size: 30px;
    padding: 6px 0px;
}

.leaflet-legend-expanded {
    padding: 6px 10px 6px 6px;
    color: #333;
    background: #fff;
}

.leaflet-legend-list {
    padding: 6px;
}

.leaflet-legend-item {
    white-space: nowrap;
    padding-bottom: 4px;
    display: flex;
    align-items: center;
    &[data-layerid=marker] svg{
        height: 18px;
        padding-left: 3px;
    }
}
.leaflet-legend-text, .leaflet-legend-color {
    display: inline-block;
}
.leaflet-legend-color {
    width: 18px;
    height: 18px;
    margin-right: 4px;
}
.leaflet-touch .leaflet-legend {
    box-shadow: none;
}
.leaflet-touch .leaflet-legend {
    border: 2px solid rgba(0,0,0,0.2);
    background-clip: padding-box;
}

.leaflet-legend-hidden {
    display: none !important;
}
/*
.leaflet-isochrones-hidden {
    display: none !important;
}

.leaflet-isochrones-title {
    @include font-size(16);
    @include line-height(18);
    font-weight: 800;
    margin-bottom: 10px;
    font-family: "MAIF";
    text-align: center;
}
.leaflet-control-layers-base {
    display: flex;
    margin-bottom: 2px;
    label.isochrone-item{
        text-align: center;
        margin-bottom: 0;
        input{
            display: none;
            &:checked + span::before{
                box-shadow: inset 1px 1px 10px rgba(50,97,109,0.2), inset -1px -1px 10px rgba(50,97,109,0.2);
            }
        }
        span::before{
            padding: 4px;
            display: block;
            border-radius: 8px;
            box-shadow: 1px 1px 5px rgba(0,0,0,0.2), -1px -1px 5px rgba(0,0,0,0.2);
            border: 1px solid rgba(128, 128, 128, 0.41);
            height: 33px;
            margin: 3px;
        }
        span.none::before{
            content: 'Aucun';
            padding: 7px 4px;
            font-size: 11px;
        }
        span[class*=icon]{
            &::before{
                font-size: 24px;
                width: 33px;
            }
            &.man-walking-icon{
                &::before{
                    content: '';
                    background: url('../resources/static/images/man-walking.svg') no-repeat center;
                    background-size: 68%;
                }
            }
            &.bike-icon{
                &::before{
                    content: '';
                    background: url('../resources/static/images/bike.svg') no-repeat center;
                    background-size: 85%;
                }
            }
        }
    }
}
*/