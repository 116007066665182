// Dashboard

.dashboard {
    .main__content {
        display: flex;

        .container {
            max-width: 1400px;
        }
    }
    .dashboard__wrapper {
        width: 100%;
        padding-left: 50px;

        @media screen and (min-width: $screen-m) {
            padding-left: 15vh;
        }

        .dashboard__header {
            background: #f4f4f4;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #2c4452;
            position: sticky;
            top: 0;
            z-index: 450;
            box-shadow: 0 0 10px rgba(0,0,0,.25);

            .heading.type3 {
                margin: 0;
                color: #2c4452;
                font-size: 1.5rem;
                line-height: 1.5rem;

                @media screen and (min-width: $screen-m) {
                    font-size: 1.8rem;
                    line-height: 1.8rem;
                }
            }
        }

        .dashboard__body {
            height: calc(100vh - 130px);
            position: relative;

            .list__card {
                padding-top: 30px;

                .card {
                    flex-shrink: 0;
                    width: 100%;
                    margin-bottom: 30px;

                    @media screen and (min-width: $screen-l) {
                        width: 49%;
                    }
                }

                .risk-list-container {
                    margin-bottom: 20px;

                    .risk-list {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        width: 100%;
                        overflow-x: auto;
                        padding-bottom: 10px;

                        .risk-entry {
                            flex-shrink: 0;
                            width: 46%;
                            margin: 0 2%;
                            margin-bottom: 4%;
                            position: relative;

                            @media screen and (min-width: $screen-s) {
                                width: 31%;
                                margin: 0 1%;
                                margin-bottom: 2%;
                            }

                            @media screen and (min-width: $screen-m) {
                                width: 23%;
                                margin: 0 1%;
                                margin-bottom: 2%;
                            }

                            @media screen and (min-width: $screen-l) {
                                width: 31%;
                                margin: 0 1%;
                                margin-bottom: 2%;
                            }

                            @media screen and (min-width: $screen-xl) {
                                width: 23%;
                            }

                            @media screen and (min-width: $screen-xxl) {
                                width: 125px;
                                margin: 0 5px;
                                margin-bottom: 10px;
                            }

                            &::after {
                                display: block;
                                content: '';
                                padding-top: 100%;
                            }

                            .risk-entry-container {
                                position: absolute;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 0;
                                padding: 5px;
                                background: #f0f0f0;
                                display: flex;
                                flex-direction: column;
                                text-align: center;
                                
                                font-size: 1rem;
                                line-height: 1rem;
                            }

                            img.aa-rating {
                                height: 20px;
                                position: absolute;
                                top: 5px;
                                left: 5px;
                            }

                            .risk-count {
                                position: absolute;
                                top: 5px;
                                left: 5px;
                            }

                            img.summary-risk-icon {
                                height: 9vw;
                                margin-top: 10px;
                                margin-bottom: 10px;

                                @media screen and (min-width: $screen-s) {
                                    height: 7vw;
                                }

                                @media screen and (min-width: $screen-m) {
                                    height: 6vw;
                                }

                                @media screen and (min-width: $screen-l) {
                                    height: 3vw;
                                }

                                @media screen and (min-width: $screen-xl) {
                                    height: 35px;
                                }
                            }

                            .risk-title, .risk-level-title {
                                font-size: 4vw;
                                line-height: 4vw;

                                @media screen and (min-width: $screen-s) {
                                    font-size: 2.5vw;
                                    line-height: 2.5vw;
                                }

                                @media screen and (min-width: $screen-m) {
                                    font-size: 2vw;
                                    line-height: 2vw;
                                }

                                @media screen and (min-width: $screen-l) {
                                    font-size: 1.3vw;
                                    line-height: 1.3vw;
                                }

                                @media screen and (min-width: $screen-xl) {
                                    font-size: 0.8rem;
                                    line-height: 1vw;
                                }
                            }

                            .risk-title {
                                font-weight: 600;
                            }
                        }
                    }
                }
            }

            .map-risques  {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }

            .map-batiments {
                width: 100%;
                height: 400px;
            }

            #table-batiments {
                &:not(.loading) {
                    .batiments-loader {
                        display: none;
                    }
                }
                &.loading {
                    .tableau-simple-version2 {
                        display: none;
                    }
                }

                img.aa-rating {
                    height: 20px;
                }
            }
        }

        .dashboard__body--table {
            height: auto;
            padding-bottom: 50px;
            
            .tableau-simple-version2 {
                overflow-x: none;

                table thead th {
                    padding: 5px;
                }

                .table-scroll {
                    overflow: auto;

                    .table-container {
                        min-width: 1000px;
                    }

                    select {
                        background: #fff;
                        border: 1px solid #5c5c5c;
                        border-radius: 2px;
                        box-shadow: none;
                        color: #000;
                        font-size: 16px;
                        padding: 8px 10px;
                        height: 48px;
                        width: 100%;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                    }
                }
            }
        }
    }
}