// Global

.debug {
    outline: 1px solid red;
}

* {
	box-sizing: border-box;
	scroll-behavior: smooth;
}

html, body {
	min-height: 100vh;
	margin:0;
	padding: 0;
}

html {
	scroll-behavior: smooth;
}

body{
    margin: 0;
    overflow: hidden;
}

.container {
	
}

.main__wrapper {
	height: 100vh;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    grid-template-areas: 'main__header'
                         'main__content'
                         'main__footer';

	.main__header {
		grid-area: main__header;
	}                     
					
	.main__content {
		grid-area: main__content;
		overflow-y: auto;
	}

	.main__footer {
		grid-area: main__footer;
	}
}