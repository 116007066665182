// Header

.main__header {
    .navbar {
        background: #2c4452;
        border-bottom: none;

        .navbar-brand {
            margin-right: 50px;
            color: #ffffff;
            font-family: 'maif,ArialReplace',sans-serif;
            font-size: 1.6rem;
            font-weight: 300;
            padding-left: 10px;

            @media screen and (min-width: $screen-l) {
                padding-left: 0;
            }
        }

        .nav-item {
            height: 50px;
            display: flex;

            a {
                height: 50px;
                padding: 8px;
                text-decoration: none;
                text-transform: inherit;
                font-size: 17px;
                font-weight: 400;
                text-align: center;
                line-height: 34px;
            }

            .active {
                background: #ffffff;
                color: #2c4452;
            }
        }
    }
}

#menu-mobile-sidebar {
    width: 300px;
    position: fixed;
    top: 0;
    right: -300px;
    height: 100vh;
    z-index: 1021;
    background: #2c4452;
    color: #fff;
    transition: all .3s;
    overflow-y: auto;
    box-shadow: 3px 3px 3px rgba(0,0,0,.2)
}

#menu-mobile-sidebar.collapse.show {
    right: 0
}

#menu-mobile-sidebar.collapse.show+.overlay-menu {
    display: block;
    opacity: 1
}

#menu-mobile-sidebar .dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%)
}

#menu-mobile-sidebar>.header {
    padding: 12px 15px;
    background: #c62222;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between
}

#menu-mobile-sidebar>.header .close-menumobile {
    display: flex;
    z-index: 9999
}

#menu-mobile-sidebar>.header .close-menumobile .maificon {
    font-size: 24px
}

#menu-mobile-sidebar>.header .close-menumobile .maificon:hover {
    transform: rotate(180deg)
}

#menu-mobile-sidebar ul {
    padding: 10px 0
}

#menu-mobile-sidebar ul:focus {
    outline: 0
}

#menu-mobile-sidebar ul p {
    color: #fff;
    padding: 10px
}

#menu-mobile-sidebar ul li {
    border-bottom: 1px dashed #999
}

#menu-mobile-sidebar ul li a {
    padding: 10px;
    font-size: 1.1rem;
    display: block;
    font-weight: 400;
    text-transform: uppercase;
    font-family: arial,sans-serif
}

#menu-mobile-sidebar ul li a:hover {
    color: #525b85;
    background: #fff
}

#menu-mobile-sidebar ul li a[data-toggle=collapse] {
    display: flex;
    justify-content: space-between
}

#menu-mobile-sidebar ul li a[data-toggle=collapse] i {
    font-size: 20px;
    color: #fff;
    transition: all .3s ease
}

#menu-mobile-sidebar ul li a[data-toggle=collapse]:hover i {
    color: #525b85
}

#menu-mobile-sidebar ul li a[aria-expanded=true] {
    background: #000
}

#menu-mobile-sidebar ul li a[aria-expanded=true] i {
    transform: rotate(180deg)
}

#menu-mobile-sidebar ul li.active a[aria-expanded=true],#menu-mobile-sidebar ul li.active>a {
    color: #fff;
    background: #d00000
}

#menu-mobile-sidebar ul.collapse.show {
    padding: 0
}

#menu-mobile-sidebar ul.collapse.show li:last-child {
    margin-bottom: 0;
    border-bottom: none
}

#menu-mobile-sidebar ul.collapse.show li a {
    padding-left: 20px;
    font-size: 1rem
}