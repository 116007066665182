
.map-risques {

    .layers-control {
        padding: 2px;
        overflow-y: hidden;
        width: 62px;
        height: 85px;
        transition: width 1s;
        text-align: center;
        padding-top: 5px;

        &.active {
            width: 170px;
        }

        .leaflet-control-layers-base {
            display: flex;
            flex-direction: row;
            align-items: center;
            overflow: hidden;

            label {
                order: 0;
                transition: order 1s;
                cursor: pointer;
                margin: 0 7px;

                &.selected {
                    order: -1;
                    transition: order 1s
                }

                input {
                    display: none
                }

                div {
                    width: 40px;
                }

                span span {
                    font-size: 0.65rem;

                    img {
                        border: 3px solid #fff;
                        border-radius: 8px;
                        height: 40px;
                        width: 40px;
                        margin: 0 auto;
                        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2), -1px -1px 10px rgba(0, 0, 0, 0.2);

                        &[id^=gray-scale] + p {
                            line-height: 12px;
                            margin: 2px 0 7px 0
                        }

                        &:not([id^=gray-scale]) + p {
                            margin: 7px 0 11px 0
                        }
                    }
                }
            }
        }
    }

    @media screen and (min-width: 992px) {
        .layers-control:hover {
            width:170px
        }
    }

}