.catnat-graph-container{
    .box-legend{
        width: 15px;
        height: 10px;
        margin: auto 7px;
        display: inline-block;
    }
    input[type=radio]:checked + label{
        font-weight: 600;
    }
    canvas{
        height: 300px;
        // max-height: 300px;
    }
}

button.clearFilter{
    padding: 0 2px;
    width: 20px;
    height: 24px;
    i{
        top: 0;
        font-size: 10px;
        margin: 0;
    }
}