// Risques

.map-risques {
    outline: 1px solid dodgerblue;
    width: 640px;
    height: 480px;

    .leaflet-legend {
        border: none;
        //display: none;
        width: 300px;
        padding: 15px;
        font-family: "MAIF";

        &.leaflet-legend-expanded {
            display: block;
            border-radius: 0;
            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
        }

        .leaflet-legend-text {
            width: calc(100% - 24px);
            white-space: pre-line;
        }

        .legend-group {
            @include font-size(16);
            @include line-height(18);
            font-weight: 800;
            span.leaflet-legend-description{
                margin-left: 5px;
                margin-top: 4px;
            }
        }
    }

    .leaflet-legend-item {
        padding-bottom: 0;
        margin-top: 5px;
    }

    .leaflet-info {
        //display: none;
        padding: 6px 10px 6px 6px;
        color: #333;
        background: #fff;
        border-radius: 0;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
        width: 300px;
        padding: 15px;
        font-family: "MAIF";

        .leaflet-info-title {
            @include font-size(16);
            @include line-height(18);
            font-weight: 800;
            margin-bottom: 0;
        }

        .leaflet-info-content p {
            margin-top: 10px;
        }

        .leaflet-info-toggle {
            display: none;
        }

        &.leaflet-info-expanded {
            display: block;
        }

        &.leaflet-info-hidden {
            display: none !important;
        }

        .leaflet-info-container {
            .leaflet-info-title-container {
                cursor: pointer;
                position: relative;

                &::after {
                    position: absolute;
                    right: 0;
                    top: 2px;
                    content: "";
                    font-family: maificon;
                    font-style: normal;
                    font-weight: 600;
                }
            }

            .leaflet-info-content {
                min-height: 1px;
                height: auto;
            }

            &.collapsed {
                .leaflet-info-title-container {
                    &::after {
                        content: "";
                    }
                }

                .leaflet-info-content {
                    height: 0;
                    overflow: hidden;
                }
            }
        }
    }

    .leaflet-legend-collapsible {
        //display: none;
        padding: 6px 10px 6px 6px;
        color: #333;
        background: #fff;
        border-radius: 0;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
        width: 300px;
        padding: 15px;
        font-family: "MAIF";

        .leaflet-legend-title-container label {
            @include font-size(16);
            @include line-height(18);
            font-weight: 800;
            margin-bottom: 0;
            max-width: 98%;
        }

        &.leaflet-legend-expanded {
            display: block;
        }

        .leaflet-legend-list {
            .leaflet-legend-title-container {
                cursor: pointer;
                position: relative;

                &::after {
                    position: absolute;
                    right: 0;
                    top: 2px;
                    content: "";
                    font-family: maificon;
                    font-style: normal;
                    font-weight: 600;
                }
            }

            .leaflet-legend-items-container {
                padding-top: 1px;
                min-height: 1px;
                height: auto;
            }

            &.collapsed {
                .leaflet-legend-title-container {
                    &::after {
                        content: "";
                    }
                }

                .leaflet-legend-items-container {
                    height: 0;
                    overflow: hidden;
                }
            }

        }
    }
}

.map-batiments {
    outline: 1px solid dodgerblue;
    width: 640px;
    height: 480px;

    img.aa-rating {
        height: 20px;
    }
}