// Page

.page-header {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 30px;
}

.page-content {
    margin-bottom: 100px;

    .check-list {
        margin-bottom: 12px;
    }

    .page-content-edito {
        display: flex;

        .mod-sommaire-article-container {
            flex-shrink: 0;
            width: 30%;
            display: none;

            @media screen and (min-width: $screen-l) {
                display: block;
            }
        }
    
        .mod-edito-paragraphe-article-container {
            flex-shrink: 0;
            width: 100%;

            @media screen and (min-width: $screen-l) {
                width: 70%;
                padding-left: 50px;
            }

            .mod {
                padding-top: 30px;
            }
        }
    }

    .card {
        p {
            margin-bottom: 15px;
        }
    }

    .main-heading {
        font-size: 28px;
        font-weight: 700;
        padding-bottom: 60px;
        margin: 0;

        @media screen and (min-width: $screen-l) {
            font-size: 40px;
        }

        > * {
            padding: 32px 40px 32px 24px;
            display: flex;
            background: #f0f0f0;
            border-radius: 12px 12px 12px 0;
        }

        .number {
            font-weight: 800;
            font-size: 45px;
            color: #c62222;
            margin-right: 20px;
            display: inline;
            font-weight: 700;
            line-height: 1;
        }
    }
}