// Home

.home {
    .main__content {
        padding-bottom: 50px;

        .container {
            max-width: 1400px;
        }

        .home__main-banner {
            display: flex;
            flex-direction: column;

            @media screen and (min-width: $screen-l) {
                flex-direction: row;
            }

            .heading {
                text-align: left;
                
                img {
                    width: 80px;
                    height: auto;
                }
            }
    
            .type2 {
                margin: 50px 0;
                font-size: 35px;

                @media screen and (min-width: $screen-xl) {
                    font-size: 52px;
                }
                
            }

            .type1 {
                font-size: 18px;

                @media screen and (min-width: $screen-xl) {
                    font-size: 24px;
                }
                
            }
    
            .home__autocomplete {
                width: 100%;
                max-width: 900px;
                margin: 0 auto;
                margin-top: 50px;
                margin-bottom: 50px;
                display: flex;
                align-items: center;
    
                .home__autocomplete__form {
                    position: relative;
                    width: 100%;
                }
    
                .maificon-bouclier-pro {
                    flex-shrink: 0;
                    font-size: 3rem;
                    color: #c62222;
                    margin-right: 15px;
                }
    
                p {
                    max-width: 900px;
                    margin: 0 auto;
                }
    
                #commune-autocomplete-container {
                    .form-group {
                        margin-bottom: 0;
                    }
                }
    
                .btn {
                    position: absolute;
                    top: 0;
                    right: 0;
                    background: #2e404f;
                    padding: 10px 7px;
                    width: 48px;
                    height: 48px;
    
                    .maificon-loupe {
                        font-size: 22px;
                        display: block;
                        height: 22px;
                        width: 22px;
                        top: 10px;
                        left: 6px;
                    }
                }
            }

            .home__main-picture {
                flex-shrink: 0;
                width: 100%;

                @media screen and (min-width: $screen-l) {
                    width: 40%;
                }
            }
        }
        
        .home__data-banner {
            padding: 50px 0;
    
            .home__data__content {
                padding: 20px 0;
            }
    
            p { 
                margin-bottom: 0;
            }
    
            ul {
                list-style-type: none;
                margin: 0;
                padding: 0;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
    
                @media screen and (min-width: $screen-m) {
                    flex-direction: row;
                    flex-wrap: wrap;
                }
    
                &.partners {
                    justify-content: flex-start;
                }
            }
    
            li {
                margin-bottom: 30px;
    
                @media screen and (min-width: $screen-m) {
                    flex-shrink: 0;
                    width: 45%;
                }
                
                @media screen and (min-width: $screen-l) {
                    width: 30%;
                }
    
                @media screen and (min-width: $screen-xl) {
                    margin-bottom: 0;
                    width: 20%;
                }
            }
    
            .risk-type {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 17px;
                height: 70px;
    
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
    
                span {
                    font-weight: 600;
                    color: #2e404f;
                }
            }
        }
    }
}