// MAIF

@charset "UTF-8";@keyframes fadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes fadeOut {
    from {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@keyframes menuOpen {
    0% {
        width: 30px;
        right: 10px
    }

    50% {
        right: 10px
    }

    100% {
        width: 70px
    }
}

@keyframes menuOpenReverse {
    to {
        width: 30px
    }

    from {
        width: 70px
    }
}

@keyframes menuOpenTopBar {
    0% {
        top: -1px
    }

    25% {
        top: 2px;
        right: 0;
        transform: rotate(0)
    }

    100% {
        right: 0;
        top: 2px;
        transform: rotate(45deg)
    }
}

@keyframes menuOpenTopBarReverse {
    0% {
        top: 2px;
        transform: rotate(45deg)
    }

    75% {
        top: 2px;
        transform: rotate(0)
    }

    100% {
        top: -1px;
        transform: rotate(0)
    }
}

@keyframes menuOpenMidBar {
    0% {
        transform: rotate(0);
        margin: 0
    }

    25% {
        transform: rotate(0);
        margin: 0
    }

    100% {
        margin: 0;
        top: -2px;
        transform: rotate(-45deg)
    }
}

@keyframes menuOpenMidBarReverse {
    0% {
        margin: 0;
        transform: rotate(-45deg)
    }

    75% {
        transform: rotate(0);
        margin: 0
    }

    100% {
        transform: rotate(0);
        margin-top: 4px
    }
}

@keyframes menuOpenBottomBar {
    0% {
        right: 0;
        opacity: 1;
        top: 0;
        content: "MENU"
    }

    25% {
        right: 0;
        opacity: 0;
        top: -10px
    }

    75% {
        right: 35px;
        opacity: 0
    }

    100% {
        left: -6px;
        opacity: 1;
        top: 8px;
        content: "FERMER"
    }
}

@keyframes menuOpenBottomBarReverse {
    0% {
        right: 0;
        opacity: 1;
        top: -10px;
        content: "FERMER"
    }

    25% {
        right: 35px;
        opacity: 0
    }

    75% {
        opacity: 0;
        top: -10px
    }

    100% {
        right: 0;
        opacity: 1;
        top: 0;
        content: "MENU"
    }
}

@keyframes anime-picto-lien-simple {
    0% {
        left: 0
    }

    50% {
        left: -4px
    }

    100% {
        left: 0
    }
}

@keyframes anime-contenu {
    0% {
        margin-right: 15px;
        opacity: 0
    }

    50% {
        margin-right: 0
    }

    100% {
        margin-right: 0;
        opacity: 1
    }
}

@keyframes anime-projet-contenu {
    0% {
        margin-left: 15px;
        opacity: 0
    }

    25% {
        opacity: 0
    }

    50% {
        margin-right: 0
    }

    100% {
        margin-left: 0;
        opacity: 1
    }
}

@keyframes anime-service {
    0% {
        margin-left: 15px;
        opacity: 0
    }

    50% {
        margin-right: 0
    }

    100% {
        margin-left: 0;
        opacity: 1
    }
}

.mt-30 {
    margin-top: 30px!important
}

.mb-30 {
    margin-bottom: 30px!important
}

.mt-20 {
    margin-top: 20px!important
}

.mb-20 {
    margin-bottom: 20px!important
}

.mt-10 {
    margin-top: 10px!important
}

.mb-10 {
    margin-bottom: 10px!important
}

.maificon-valid-RGAA::before {
    color: #2d871d
}

.editorial.page-article .heading {
    font-family: maif,ArialReplace,sans-serif;
    color: #000;
    display: block;
    line-height: 1.2
}

.editorial.page-article .heading.type-article-1 {
    font-size: 32px;
    font-weight: 400;
    margin-bottom: 24px
}

.editorial.page-article .heading.type-article-1 .highlight {
    font-weight: 700;
    color: #c62222
}

.editorial.page-article .heading.type-article-2 {
    padding: 42px 0 40px;
    font-size: 28px;
    font-weight: 700;
    width: 100%
}

.editorial.page-article .heading.type-article-2>* {
    display: flex;
    padding: 16px;
    background: #f0f0f0;
    border-radius: 12px 12px 12px 0
}

.editorial.page-article .heading.type-article-2>* .number {
    font-size: 35px;
    color: #c62222;
    margin-right: 20px;
    display: inline;
    font-weight: 700;
    line-height: 1
}

.editorial.page-article .heading.type-article-3 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 24px
}

.editorial.page-article .heading.type-article-4 {
    font-size: 20px;
    margin-bottom: 16px
}

.editorial.page-article .heading.type-article-5 {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 40px
}

.editorial.page-article .heading.type-article-6 {
    font-size: 18px;
    margin: auto 0;
    transition: all .2s ease
}

.editorial.page-article .heading.type-article-7 {
    font-size: 20px;
    margin-bottom: 12px;
    font-weight: 700
}

.page-article section .container .mod:last-child {
    margin-bottom: 40px
}

.page-article .container .mod:last-child {
    margin-bottom: 40px
}

.page-article .mod-bouton-haut {
    margin-top: 0
}

.container-edito {
    color: #333
}

.container-edito p {
    margin-bottom: 8px
}

.container-edito ul.bullet-list li {
    margin-bottom: 8px
}

.container-edito .ref-link {
    scroll-margin-top: 80px
}

section.background {
    background: #f0f0f0
}

.mod-chapeau-article {
    position: relative
}

.mod-chapeau-article .content-title p.theme {
    font-size: 20px;
    font-weight: 300;
    text-transform: uppercase;
    font-family: maif,ArialReplace,sans-serif;
    margin-bottom: 0;
    color: #000
}

.mod-chapeau-article .content-edito .edito-infos .detail {
    display: flex;
    justify-content: space-between
}

.mod-chapeau-article .content-edito .edito-infos .detail .quality {
    margin-bottom: 0
}

.mod-chapeau-article .content-edito .edito-infos .detail .time {
    margin: 0 0 0 10px;
    min-width: 90px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-content: space-between;
    align-items: flex-end
}

.mod-chapeau-article .content-edito .edito-infos .detail .time .maificon {
    margin-left: 8px;
    line-height: 1.5;
    font-size: 16px
}

.mod-chapeau-article .content-edito .edito-infos hr {
    color: #cfcfcf;
    margin: 10px 0
}

.mod-chapeau-article .content-edito .edito-infos p.maj {
    font-size: 16px;
    color: #5c5c5c
}

.mod-chapeau-article.with-img .media {
    margin-bottom: 24px;
    position: relative;
    width: 100%
}

.mod-chapeau-article.with-img .media img {
    width: 100%
}

.mod-sommaire-article {
    margin: 0 -10px!important;
    height: 50px
}

.mod-sommaire-article.affix-top .container-sticky {
    position: fixed;
    background: #fff;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 20
}

.mod-sommaire-article .dropdown button {
    width: 100%;
    color: #c62222
}

.mod-sommaire-article .dropdown i {
    transition: all .3s ease
}

.mod-sommaire-article .dropdown.show i {
    transform: rotate(180deg);
    transition: all .3s ease
}

.mod-sommaire-article .title {
    width: 100%;
    color: #c62222;
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;
    font-family: maif,ArialReplace,sans-serif;
    margin-bottom: 8px
}

.mod-sommaire-article .bg-progress {
    position: absolute;
    bottom: -10px;
    background: #f0f0f0;
    width: 100%;
    height: 10px;
    z-index: 10000;
    left: 0
}

.mod-sommaire-article .bg-progress .progress {
    height: 10px;
    background: #c62222;
    width: 0
}

.mod-sommaire-article .dropdown-toggle {
    display: flex;
    align-items: center;
    width: 100%
}

.mod-sommaire-article .dropdown-toggle span {
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;
    font-family: maif,ArialReplace,sans-serif;
    padding: 10px 0
}

.mod-sommaire-article .dropdown-toggle::after {
    display: none
}

.mod-sommaire-article .dropdown-toggle i {
    position: absolute;
    right: 20px;
    color: #000;
    font-size: 12px
}

.mod-sommaire-article .dropdown-menu {
    width: 100%;
    border: none;
    font-size: 16;
    font-weight: 400px;
    box-shadow: 0 0 8px 0 #a7a7a7;
    padding: 0 10px
}

.mod-sommaire-article .dropdown-menu.show {
    transform: translate3d(0,50px,0)!important
}

.mod-sommaire-article ol.counter-list {
    list-style: none;
    counter-reset: li;
    font-family: maif,ArialReplace,sans-serif;
    padding-left: 0;
    color: #333;
    margin-top: 30px
}

.mod-sommaire-article ol.counter-list li {
    counter-increment: li;
    line-height: 20.8px;
    display: flex;
    font-size: 16px;
    padding: 12px 25px 12px 0;
    transition: all .2s ease;
    margin-bottom: 10px
}

.mod-sommaire-article ol.counter-list li:hover {
    transition: all .3s ease;
    padding: 12px 15px 12px 10px
}

.mod-sommaire-article ol.counter-list li:hover::before {
    color: #c62222
}

.mod-sommaire-article ol.counter-list li.active {
    background: #f0f0f0;
    width: 100%;
    padding: 12px 15px 12px 10px;
    border-radius: 8px 8px 8px 0;
    font-weight: 700;
    transition: all .2s ease
}

.mod-sommaire-article ol.counter-list li.active::before {
    color: #c62222
}

.mod-sommaire-article ol.counter-list li::before {
    content: counter(li);
    display: inline-block;
    margin-right: 8px;
    font-weight: 700;
    font-family: maif,ArialReplace,sans-serif;
    color: #000;
    font-size: 20px;
    white-space: nowrap
}

.mod-edito-paragraphe-article .type-article-2 {
    scroll-margin-top: 70px
}

.mod-zone-similaire .grid {
    display: grid;
    grid-gap: 20px;
    position: relative;
    margin-bottom: 40px
}

.mod-zone-similaire.cartes-3 .grid {
    grid-template-columns: initial
}

.mod-zone-similaire.cartes-3 .grid .block-article:hover .media {
    border-radius: 10px 0 0 10px
}

.mod-zone-similaire.cartes-3 .grid .block-article .media {
    display: none
}

.mod-zone-similaire.cartes-4 .grid .block-article:hover .media {
    border-radius: 10px 0 0 10px
}

.mod-zone-similaire.cartes-4 .grid .block-article .media {
    display: none
}

.mod-zone-similaire.cartes-4 .grid .block-article .content p {
    display: none
}

.mod-zone-similaire.cartes-4 .grid .block-article:first-child:hover .media {
    border-radius: 10px 10px 0 0
}

.mod-zone-similaire.cartes-4 .grid .block-article.background {
    background: #fff;
    border: none
}

.mod-zone-similaire.cocon .grid .block-article {
    grid-column-gap: 0;
    border: 3px solid #f0f0f0
}

.mod-zone-similaire.cocon .grid .block-article .content .heading.type-article-6 {
    margin: auto 0
}

.mod-zone-similaire.cocon .grid .block-article.background {
    border: 3px solid #fff
}

.mod-media {
    border: 3px solid #f0f0f0;
    border-radius: 12px 12px 12px 0;
    overflow: hidden
}

.mod-media .wrapper-link {
    display: inline-grid;
    grid-gap: 20px
}

.mod-media .wrapper-link :last-child {
    margin-bottom: 20px
}

.mod-media>.heading.type-article-7 {
    padding: 20px 20px 0
}

.mod-media .content {
    padding: 0 20px
}

.mod-media .content p {
    margin-bottom: 12px
}

.mod-media .content>a {
    margin-bottom: 20px;
    display: block;
    width: -moz-fit-content;
    width: fit-content
}

.mod-media .content>a:last-child {
    margin-bottom: 0
}

.mod-media .media {
    margin-top: 20px
}

.mod-media .media:first-child {
    margin-top: 0
}

.mod-media .media~.content {
    margin: 20px 0;
    line-height: initial
}

.mod-media.background {
    background: #f0f0f0;
    border: none
}

.mod-liste-article {
    padding: 32px 0
}

.mod-liste-article .liste-articles {
    display: grid;
    grid-gap: 20px;
    position: relative;
    margin-bottom: 40px
}

.block-pagination {
    text-align: center;
    font-family: maif,ArialReplace,sans-serif;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center
}

.block-pagination .next-link,.block-pagination .page,.block-pagination .prev-link {
    display: flex;
    align-items: center
}

.block-pagination .next-link,.block-pagination .prev-link {
    color: #000;
    font-size: 18px;
    padding: 16px
}

.block-pagination .next-link .maificon,.block-pagination .prev-link .maificon {
    font-size: 20px;
    color: #c62222
}

.block-pagination .next-link.disabled,.block-pagination .prev-link.disabled {
    opacity: .5;
    pointer-events: none
}

.block-pagination .next-link span,.block-pagination .prev-link span {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0
}

.block-pagination .content {
    background: #f0f0f0;
    border-radius: 50px;
    display: flex;
    padding: 5px 7px
}

.block-pagination .content .number {
    background: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    transition: all .2s ease-in-out;
    margin-right: 14px
}

.block-pagination .content .number span {
    font-size: 20px
}

.block-pagination .content .number:hover {
    color: #c62222;
    transition: all .2s ease-in-out
}

.block-pagination .content .number:last-of-type {
    margin-right: 0
}

.block-pagination .content .number.active {
    background: #c62222;
    color: #fff
}

.block-pagination .content .number.active span {
    font-weight: 500
}

.block-pagination.background .content {
    background: #fff
}

.block-pagination.background .content .number {
    background: #f0f0f0
}

.block-pagination.background .content .number.active {
    background: #c62222;
    color: #fff
}

.mod-sous-thematique {
    text-align: center
}

.mod-sous-thematique nav ul {
    list-style: none;
    overflow: auto;
    white-space: nowrap;
    margin: 0;
    text-align: center;
    padding: 0
}

.mod-sous-thematique nav ul li {
    padding-right: 20px;
    display: inline-block;
    margin-bottom: 10px
}

.mod-sous-thematique nav ul li a {
    transition: all .1s ease-in-out
}

.mod-sous-thematique nav ul li a:hover {
    transition: all .2s ease-in-out;
    color: #c62222
}

.mod-sous-thematique nav .nav-item {
    font-family: maif,ArialReplace,sans-serif;
    font-size: 16px;
    padding: 8px 12px;
    position: relative;
    display: block;
    border: 1px solid currentcolor;
    border-radius: 50px;
    text-decoration: none;
    color: #000;
    background: #fff
}

.mod-zone-intro-commun {
    text-align: center;
    padding: 40px 0 0
}

.mod-zone-intro-commun .media {
    margin: 0 auto 20px;
    width: 293px
}

.mod-zone-intro-commun .media img {
    position: initial
}

.mod-zone-intro-commun .content {
    margin: auto 0
}

.mod-zone-intro-commun .content>.heading.type-article-1 {
    margin-bottom: 12px!important
}

.mod-zone-intro-commun.auteur {
    text-align: left
}

.mod-zone-intro-commun.auteur .media {
    width: 128px;
    height: 128px;
    margin: 0 0 20px
}

.mod-zone-intro-commun.auteur .media img {
    border-radius: 50%
}

.mod-zone-intro-commun.auteur .heading.type-article-1 {
    margin-bottom: 4px!important
}

.mod-zone-intro-commun.auteur .profile-headline {
    margin-bottom: 12px
}

.mod-zone-intro-commun.auteur .profile-headline p {
    font-style: italic;
    font-size: 18px;
    margin-bottom: 0
}

.mod-zone-intro-commun.auteur .social-links {
    display: flex;
    align-items: center;
    justify-content: flex-start
}

.mod-zone-intro-commun.auteur .social-links a {
    margin-right: 12px;
    line-height: 0
}

.mod-zone-intro-commun.auteur .social-links .maificon {
    font-size: 28px
}

.mod-mise-en-exergue {
    display: flex
}

.mod-mise-en-exergue svg {
    width: 40px;
    height: 100%;
    fill: #c62222
}

.mod-mise-en-exergue .bordure {
    width: 20px;
    background: #c62222;
    border-radius: 5px 5px 5px 0
}

.mod-mise-en-exergue .content {
    margin-left: 20px;
    flex: 1
}

.mod-mise-en-exergue .content blockquote {
    margin: 0
}

.mod-mise-en-exergue .content .title {
    font-size: 20px;
    color: #000;
    font-weight: 700
}

.mod-mise-en-exergue .content p:last-child {
    margin-bottom: 0
}

.mod-maillage-interne {
    border-top: 1px solid #c62222
}

.mod-maillage-interne .content {
    padding: 40px 30px 14px 34px
}

.mod-maillage-interne .content .title {
    color: #c62222;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 700
}

.mod-maillage-interne .footer {
    display: flex;
    align-items: flex-end
}

.mod-maillage-interne .footer::before {
    flex: 1;
    content: "";
    height: 1px;
    background: #c62222
}

.mod-maillage-interne .footer>a {
    margin-left: 20px
}

.block-carte-categorie {
    padding: 20px;
    border-radius: 8px;
    border: 2px solid #f0f0f0;
    display: flex;
    transition: all .15s ease-in-out;
    position: relative;
    justify-content: space-between;
    background: #fff
}

.block-carte-categorie:hover {
    border: 2px solid #c62222;
    transform: scale(1.015);
    box-shadow: 0 8px 9px 0 rgba(0,0,0,.2)
}

.block-carte-categorie .title {
    font-weight: 400;
    margin-bottom: 8px;
    font-family: maif,ArialReplace,sans-serif;
    font-size: 24px;
    color: #000;
    line-height: 1.2
}

.block-carte-categorie .title>p {
    margin-bottom: 0
}

.block-carte-categorie .title a {
    text-decoration: none
}

.block-carte-categorie .title a:hover {
    color: #000
}

.block-carte-categorie .text {
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    margin: 0
}

.block-carte-categorie .maificon {
    display: flex;
    align-items: center;
    color: #c62222;
    margin-left: 10px
}

.mod-snack-content {
    border-radius: 12px 12px 12px 0;
    border: 3px solid #f0f0f0;
    padding: 20px
}

.mod-snack-content .title {
    font-weight: 700;
    color: #c62222;
    font-size: 24px;
    margin-bottom: 8px
}

.mod-snack-content.background {
    background: #f0f0f0
}

.mod-snack-content.highlight {
    background: #c62222;
    border-color: #c62222;
    color: #fff;
    margin-top: 100px
}

.mod-snack-content.highlight .title {
    color: #fff;
    font-size: 28px;
    line-height: 1.2
}

.mod-navigation-thematique {
    margin-top: 32px
}

.mod-navigation-thematique .title-nav {
    font-size: 28px;
    line-height: 1.2;
    font-family: maif,ArialReplace,sans-serif;
    margin-bottom: 32px
}

.mod-navigation-thematique .title-nav.title-2 {
    font-size: 24px;
    font-weight: 700
}

.mod-navigation-thematique .liste-carte-categories {
    display: grid;
    margin-bottom: 40px;
    grid-gap: 20px
}

.mod-navigation-thematique nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: -10px
}

.mod-navigation-thematique nav ul li {
    padding-right: 10px;
    transition: all .1s ease;
    display: inline-block;
    margin-bottom: 10px
}

.mod-navigation-thematique nav .nav-item {
    font-family: maif,ArialReplace,sans-serif;
    padding: 8px 12px;
    height: 100%;
    display: block;
    border-radius: 50px;
    text-decoration: none;
    background: #f0f0f0
}

.edito-article .mod-navigation-thematique nav .nav-item {
    background: #fff
}

.mod-navigation-thematique nav .nav-item:hover {
    color: #fff;
    background: #525b85
}

.mod-ressources-mixte .grid {
    display: grid;
    grid-gap: 20px;
    position: relative;
    margin-bottom: 40px
}

.block-article {
    display: grid;
    margin: 0;
    transition: all .2s ease;
    position: relative;
    border: 3px solid #f0f0f0;
    border-radius: 10px
}

.block-article:hover .media {
    transition: all .2s ease;
    border-radius: 10px 0 0 10px
}

.block-article .media {
    display: none
}

.block-article .content {
    padding: 20px 15px;
    display: inline-flex;
    justify-content: space-between
}

.block-article .content a {
    text-decoration: underline;
    -webkit-text-decoration-color: transparent;
    text-decoration-color: transparent;
    transition: all .2s ease
}

.block-article .content a:hover {
    -webkit-text-decoration-color: #c62222;
    text-decoration-color: #c62222;
    transition: all .2s ease;
    color: #c62222
}

.block-article .content .maificon {
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: #c62222;
    margin-left: 22px
}

.block-article .content p {
    display: none
}

.block-article.background {
    background: #fff;
    border: none
}

.block-article.auteur {
    grid-template-columns: auto 1fr
}

.block-article.auteur .subtitle {
    font-style: italic;
    font-family: arial,sans-serif;
    font-size: 18px;
    font-weight: 400;
    display: block;
    margin-bottom: 0
}

.block-article.auteur .media {
    display: block;
    width: 60px;
    margin: 20px 0 20px 20px
}

.block-article.auteur .maificon {
    display: none
}

.block-ressource-externe {
    display: flex;
    padding: 20px;
    border: 3px solid #f0f0f0;
    border-radius: 10px
}

.block-ressource-externe>.maificon {
    display: none
}

.block-ressource-externe .content {
    margin-left: 0
}

.block-ressource-externe .content .heading.type-article-6 {
    margin-bottom: 8px
}

.block-ressource-externe .content .description p {
    display: none
}

.block-ressource-externe.background {
    border: 3px solid #fff;
    background: #fff
}

.mod-plus {
    padding: 24px 20px;
    border: 3px solid #f0f0f0;
    border-radius: 12px 12px 12px 0
}

.mod-plus .title {
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 1.2;
    margin-bottom: 12px;
    font-weight: 700
}

.mod-plus .title .media {
    margin-right: 15px
}

.mod-plus .title .media canvas,.mod-plus .title .media img {
    width: 32px
}

.mod-plus .content .subtitle {
    text-align: left;
    color: #000;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 24px
}

.mod-plus .content ul {
    margin-bottom: 16px
}

.mod-plus .content .wrapper-link {
    display: flex;
    flex-direction: column;
    align-items: flex-start
}

.mod-plus .content .wrapper-link a {
    margin-bottom: 16px
}

.mod-plus .content .wrapper-link a:last-child {
    margin-bottom: 0
}

.mod-plus .content .form-legend {
    margin: 10px 0 0
}

.mod-plus.background {
    border: none;
    background: #f0f0f0
}

.grid-swipe {
    display: grid;
    grid-template-rows: repeat(var(--bs-rows,1),1fr);
    grid-template-columns: repeat(var(--bs-columns,12),1fr);
    gap: var(--bs-gap,20px)
}

.grid-swipe .g-col-1 {
    grid-column: auto/span 1
}

.grid-swipe .g-col-2 {
    grid-column: auto/span 2
}

.grid-swipe .g-col-3 {
    grid-column: auto/span 3
}

.grid-swipe .g-col-4 {
    grid-column: auto/span 4
}

.grid-swipe .g-col-5 {
    grid-column: auto/span 5
}

.grid-swipe .g-col-6 {
    grid-column: auto/span 6
}

.grid-swipe .g-col-7 {
    grid-column: auto/span 7
}

.grid-swipe .g-col-8 {
    grid-column: auto/span 8
}

.grid-swipe .g-col-9 {
    grid-column: auto/span 9
}

.grid-swipe .g-col-10 {
    grid-column: auto/span 10
}

.grid-swipe .g-col-11 {
    grid-column: auto/span 11
}

.grid-swipe .g-col-12 {
    grid-column: auto/span 12
}

.grid-swipe .g-start-1 {
    grid-column-start: 1
}

.grid-swipe .g-start-2 {
    grid-column-start: 2
}

.grid-swipe .g-start-3 {
    grid-column-start: 3
}

.grid-swipe .g-start-4 {
    grid-column-start: 4
}

.grid-swipe .g-start-5 {
    grid-column-start: 5
}

.grid-swipe .g-start-6 {
    grid-column-start: 6
}

.grid-swipe .g-start-7 {
    grid-column-start: 7
}

.grid-swipe .g-start-8 {
    grid-column-start: 8
}

.grid-swipe .g-start-9 {
    grid-column-start: 9
}

.grid-swipe .g-start-10 {
    grid-column-start: 10
}

.grid-swipe .g-start-11 {
    grid-column-start: 11
}

@media (min-width: 576px) {
    .mod-zone-similaire .grid {
        grid-template-columns:repeat(2,auto);
        grid-gap: 20px
    }

    .mod-zone-similaire.cartes-3 .grid {
        grid-gap: 30px
    }

    .mod-zone-similaire.cartes-3 .grid .block-article {
        grid-template-columns: repeat(2,auto);
        grid-column-gap: 20px;
        border: none
    }

    .mod-zone-similaire.cartes-3 .grid .block-article .media {
        display: block;
        border-radius: 10px;
        overflow: hidden;
        width: 185px;
        height: 185px
    }

    .mod-zone-similaire.cartes-3 .grid .block-article .content {
        padding: 0;
        display: block
    }

    .mod-zone-similaire.cartes-3 .grid .block-article .content .maificon {
        display: none
    }

    .mod-zone-similaire.cartes-3 .grid .block-article .content p {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden
    }

    .mod-zone-similaire.cartes-3 .grid .block-article.background {
        background: 0 0
    }

    .mod-zone-similaire.cartes-4 .grid {
        grid-gap: 40px
    }

    .mod-zone-similaire.cartes-4 .grid .block-article {
        grid-gap: 18px;
        grid-template-columns: repeat(2,auto);
        border: none
    }

    .mod-zone-similaire.cartes-4 .grid .block-article .media {
        display: block;
        border-radius: 10px;
        overflow: hidden;
        width: 185px;
        height: 185px
    }

    .mod-zone-similaire.cartes-4 .grid .block-article .content {
        padding: 0;
        display: block
    }

    .mod-zone-similaire.cartes-4 .grid .block-article .content .maificon {
        display: none
    }

    .mod-zone-similaire.cartes-4 .grid .block-article .content p {
        font-size: 18px;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden
    }

    .mod-zone-similaire.cartes-4 .grid .block-article.background {
        background: inherit
    }

    .mod-liste-article .liste-articles {
        grid-template-columns: repeat(2,auto)
    }

    .block-pagination .next-link .maificon,.block-pagination .prev-link .maificon {
        font-size: 16px
    }

    .block-pagination .next-link span,.block-pagination .prev-link span {
        position: unset;
        width: unset;
        height: unset;
        padding: 0;
        margin: 0;
        overflow: unset;
        clip: unset;
        white-space: unset;
        border: unset;
        display: flex;
        padding: 0 10px
    }

    .mod-zone-intro-commun .media {
        width: 339px
    }

    .mod-mise-en-exergue svg {
        width: 74px
    }

    .mod-mise-en-exergue .content .title {
        font-size: 24px
    }

    .block-carte-categorie .title {
        font-size: 28px
    }

    .mod-snack-content.highlight .title {
        font-size: 40px
    }

    .mod-navigation-thematique .liste-carte-categories {
        grid-template-columns: repeat(2,auto);
        grid-gap: 40px
    }

    .mod-ressources-mixte .grid {
        grid-template-columns: repeat(2,50%)
    }

    .grid-swipe .g-col-sm-1 {
        grid-column: auto/span 1
    }

    .grid-swipe .g-col-sm-2 {
        grid-column: auto/span 2
    }

    .grid-swipe .g-col-sm-3 {
        grid-column: auto/span 3
    }

    .grid-swipe .g-col-sm-4 {
        grid-column: auto/span 4
    }

    .grid-swipe .g-col-sm-5 {
        grid-column: auto/span 5
    }

    .grid-swipe .g-col-sm-6 {
        grid-column: auto/span 6
    }

    .grid-swipe .g-col-sm-7 {
        grid-column: auto/span 7
    }

    .grid-swipe .g-col-sm-8 {
        grid-column: auto/span 8
    }

    .grid-swipe .g-col-sm-9 {
        grid-column: auto/span 9
    }

    .grid-swipe .g-col-sm-10 {
        grid-column: auto/span 10
    }

    .grid-swipe .g-col-sm-11 {
        grid-column: auto/span 11
    }

    .grid-swipe .g-col-sm-12 {
        grid-column: auto/span 12
    }

    .grid-swipe .g-start-sm-1 {
        grid-column-start: 1
    }

    .grid-swipe .g-start-sm-2 {
        grid-column-start: 2
    }

    .grid-swipe .g-start-sm-3 {
        grid-column-start: 3
    }

    .grid-swipe .g-start-sm-4 {
        grid-column-start: 4
    }

    .grid-swipe .g-start-sm-5 {
        grid-column-start: 5
    }

    .grid-swipe .g-start-sm-6 {
        grid-column-start: 6
    }

    .grid-swipe .g-start-sm-7 {
        grid-column-start: 7
    }

    .grid-swipe .g-start-sm-8 {
        grid-column-start: 8
    }

    .grid-swipe .g-start-sm-9 {
        grid-column-start: 9
    }

    .grid-swipe .g-start-sm-10 {
        grid-column-start: 10
    }

    .grid-swipe .g-start-sm-11 {
        grid-column-start: 11
    }
}

@media (min-width: 768px) {
    .editorial.page-article .heading.type-article-1 {
        font-size:40px
    }

    .editorial.page-article .heading.type-article-2 {
        font-size: 32px;
        padding: 60px 0 40px
    }

    .editorial.page-article .heading.type-article-2>* {
        padding: 24px 40px 24px 24px
    }

    .editorial.page-article .heading.type-article-2>* .number {
        font-weight: 800;
        font-size: 45px
    }

    .editorial.page-article .heading.type-article-4 {
        font-size: 24px
    }

    .editorial.page-article .heading.type-article-5 {
        font-size: 32px
    }

    .editorial.page-article .heading.type-article-6 {
        font-size: 20px
    }

    .editorial.page-article .heading.type-article-7 {
        font-size: 24px
    }

    .mod-chapeau-article {
        margin-bottom: 100px
    }

    .mod-chapeau-article .content-title p.theme {
        font-size: 24px
    }

    .mod-sommaire-article {
        position: sticky;
        background: #fff;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 20;
        height: auto;
        margin: 0 0 40px!important
    }

    .mod-sommaire-article ol.counter-list li::before {
        font-weight: 800
    }

    .mod-edito-paragraphe-article:first-child .heading.type-article-2:first-child {
        padding-top: 0
    }

    .mod-zone-similaire .grid {
        grid-gap: 40px
    }

    .mod-zone-similaire.cartes-3 .grid {
        grid-gap: 40px
    }

    .mod-zone-similaire.cartes-4 .grid {
        grid-column-gap: 85px;
        grid-template-columns: 32% auto
    }

    .mod-zone-similaire.cartes-4 .grid .block-article:first-child {
        grid-column: 1;
        grid-row: 1/4;
        display: block
    }

    .mod-zone-similaire.cartes-4 .grid .block-article:first-child .media {
        margin-bottom: 18px;
        width: 236px;
        height: auto
    }

    .mod-zone-similaire.cartes-4 .grid .block-article:first-child .content {
        max-width: 236px
    }

    .mod-zone-similaire.cocon .grid {
        grid-template-columns: repeat(2,auto)
    }

    .mod-liste-article .liste-articles {
        grid-gap: 40px
    }

    .mod-sous-thematique nav ul {
        padding: 12px 0;
        white-space: initial
    }

    .mod-zone-intro-commun {
        display: flex;
        text-align: left;
        padding: 72px 0 32px
    }

    .mod-zone-intro-commun .media {
        width: 235px;
        margin: 0 20px 0 0
    }

    .mod-zone-intro-commun.auteur .media {
        width: 185px;
        height: 185px;
        margin: 0 40px 0 0
    }

    .mod-zone-intro-commun.auteur .social-links {
        justify-content: flex-end
    }

    .mod-snack-content {
        padding: 20px 44px
    }

    .mod-navigation-thematique .title-nav {
        font-size: 32px
    }

    .mod-plus {
        padding: 32px 70px 32px 25px
    }

    .mod-plus .title {
        font-size: 24px
    }

    .mod-plus .title .media canvas,.mod-plus .title .media img {
        width: 48px
    }

    .mod-plus .content {
        margin-left: 65px
    }

    .grid-swipe .g-col-md-1 {
        grid-column: auto/span 1
    }

    .grid-swipe .g-col-md-2 {
        grid-column: auto/span 2
    }

    .grid-swipe .g-col-md-3 {
        grid-column: auto/span 3
    }

    .grid-swipe .g-col-md-4 {
        grid-column: auto/span 4
    }

    .grid-swipe .g-col-md-5 {
        grid-column: auto/span 5
    }

    .grid-swipe .g-col-md-6 {
        grid-column: auto/span 6
    }

    .grid-swipe .g-col-md-7 {
        grid-column: auto/span 7
    }

    .grid-swipe .g-col-md-8 {
        grid-column: auto/span 8
    }

    .grid-swipe .g-col-md-9 {
        grid-column: auto/span 9
    }

    .grid-swipe .g-col-md-10 {
        grid-column: auto/span 10
    }

    .grid-swipe .g-col-md-11 {
        grid-column: auto/span 11
    }

    .grid-swipe .g-col-md-12 {
        grid-column: auto/span 12
    }

    .grid-swipe .g-start-md-1 {
        grid-column-start: 1
    }

    .grid-swipe .g-start-md-2 {
        grid-column-start: 2
    }

    .grid-swipe .g-start-md-3 {
        grid-column-start: 3
    }

    .grid-swipe .g-start-md-4 {
        grid-column-start: 4
    }

    .grid-swipe .g-start-md-5 {
        grid-column-start: 5
    }

    .grid-swipe .g-start-md-6 {
        grid-column-start: 6
    }

    .grid-swipe .g-start-md-7 {
        grid-column-start: 7
    }

    .grid-swipe .g-start-md-8 {
        grid-column-start: 8
    }

    .grid-swipe .g-start-md-9 {
        grid-column-start: 9
    }

    .grid-swipe .g-start-md-10 {
        grid-column-start: 10
    }

    .grid-swipe .g-start-md-11 {
        grid-column-start: 11
    }
}

@media (min-width: 992px) {
    .editorial.page-article .heading.type-article-1 {
        font-size:52px;
        margin-bottom: 36px;
        font-weight: 300
    }

    .editorial.page-article .heading.type-article-1 .highlight {
        font-weight: 700
    }

    .editorial.page-article .heading.type-article-2 {
        font-size: 40px
    }

    .editorial.page-article .heading.type-article-2>* {
        padding: 32px 40px 32px 24px
    }

    .editorial.page-article .heading.type-article-3 {
        font-size: 28px
    }

    .editorial.page-article .heading.type-article-5 {
        font-size: 40px
    }

    .editorial.page-article .heading.type-article-6 {
        font-weight: 700;
        margin-bottom: 8px
    }

    .mod-chapeau-article.with-img {
        min-height: 454px
    }

    .mod-chapeau-article.with-img .media {
        position: absolute;
        width: calc(100% - 20px)
    }

    .mod-chapeau-article.with-img .content-title {
        margin-left: 20px
    }

    .mod-chapeau-article.with-img .content-edito {
        margin-left: 20px
    }

    .mod-sommaire-article.affix-top .container-sticky {
        position: initial
    }

    .mod-sommaire-article .bg-progress {
        position: unset;
        margin-bottom: 12px
    }

    .mod-sommaire-article .dropdown-menu {
        position: unset;
        float: none;
        display: block;
        box-shadow: none;
        padding: 0
    }

    .mod-sommaire-article ol.counter-list {
        margin-top: 0
    }

    .mod-edito-paragraphe-article .type-article-2 {
        scroll-margin-top: 20px
    }

    .mod-zone-similaire.cartes-3 .grid {
        grid-template-columns: repeat(3,1fr);
        grid-gap: 60px
    }

    .mod-zone-similaire.cartes-3 .grid .block-article {
        display: block
    }

    .mod-zone-similaire.cartes-3 .grid .block-article:hover .media {
        border-radius: 10px 10px 0 0
    }

    .mod-zone-similaire.cartes-3 .grid .block-article .media {
        width: 280px;
        height: 280px;
        margin-bottom: 20px
    }

    .mod-zone-similaire.cartes-3 .grid .block-article .content {
        max-width: 280px
    }

    .mod-zone-similaire.cartes-4 .grid {
        grid-column-gap: 100px
    }

    .mod-zone-similaire.cartes-4 .grid .block-article:first-child .media {
        width: 307px
    }

    .mod-zone-similaire.cartes-4 .grid .block-article:first-child .content {
        max-width: 307px
    }

    .mod-zone-similaire.cocon .grid {
        grid-template-columns: repeat(3,auto)
    }

    .mod-zone-similaire.cocon .grid .block-article .content {
        padding: 20px 15px;
        display: inline-flex
    }

    .mod-zone-similaire.cocon .grid .block-article .content .maificon {
        display: flex
    }

    .mod-zone-similaire.cocon .grid .block-article.background {
        background: #fff
    }

    .mod-zone-similaire .link-highlight {
        display: block;
        width: -moz-fit-content;
        width: fit-content;
        margin-left: auto
    }

    .mod-zone-intro-commun .media {
        width: 307px
    }

    .mod-navigation-thematique .title-nav {
        font-size: 40px
    }

    .mod-navigation-thematique .liste-carte-categories {
        margin-bottom: 60px
    }

    .mod-ressources-mixte .grid {
        grid-column-gap: 40px
    }

    .mod-ressources-mixte .link-highlight {
        display: block;
        width: -moz-fit-content;
        width: fit-content;
        margin-left: auto
    }

    .block-article {
        grid-column-gap: 20px;
        grid-template-columns: repeat(2,auto);
        border: none
    }

    .block-article .media {
        display: block;
        border-radius: 10px;
        overflow: hidden;
        width: 185px;
        height: 185px;
        transition: all .2s ease
    }

    .block-article .content {
        display: block;
        padding: 0
    }

    .block-article .content .maificon {
        display: none
    }

    .block-article .content p {
        margin-bottom: 0;
        font-size: 18px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        overflow: hidden
    }

    .block-article.background {
        background: inherit
    }

    .block-article.auteur .media {
        margin: initial;
        width: 185px
    }

    .block-ressource-externe>.maificon {
        color: #c62222;
        font-size: 32px;
        display: block
    }

    .block-ressource-externe .content {
        margin-left: 22px
    }

    .block-ressource-externe .content .description p {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: 18px;
        margin-bottom: 8px
    }

    .block-ressource-externe.background {
        background: rgba(255,255,255,.6);
        border: rgba(255,255,255,.6)
    }

    .mod-plus .content {
        margin-left: 69px
    }

    .grid-swipe .g-col-lg-1 {
        grid-column: auto/span 1
    }

    .grid-swipe .g-col-lg-2 {
        grid-column: auto/span 2
    }

    .grid-swipe .g-col-lg-3 {
        grid-column: auto/span 3
    }

    .grid-swipe .g-col-lg-4 {
        grid-column: auto/span 4
    }

    .grid-swipe .g-col-lg-5 {
        grid-column: auto/span 5
    }

    .grid-swipe .g-col-lg-6 {
        grid-column: auto/span 6
    }

    .grid-swipe .g-col-lg-7 {
        grid-column: auto/span 7
    }

    .grid-swipe .g-col-lg-8 {
        grid-column: auto/span 8
    }

    .grid-swipe .g-col-lg-9 {
        grid-column: auto/span 9
    }

    .grid-swipe .g-col-lg-10 {
        grid-column: auto/span 10
    }

    .grid-swipe .g-col-lg-11 {
        grid-column: auto/span 11
    }

    .grid-swipe .g-col-lg-12 {
        grid-column: auto/span 12
    }

    .grid-swipe .g-start-lg-1 {
        grid-column-start: 1
    }

    .grid-swipe .g-start-lg-2 {
        grid-column-start: 2
    }

    .grid-swipe .g-start-lg-3 {
        grid-column-start: 3
    }

    .grid-swipe .g-start-lg-4 {
        grid-column-start: 4
    }

    .grid-swipe .g-start-lg-5 {
        grid-column-start: 5
    }

    .grid-swipe .g-start-lg-6 {
        grid-column-start: 6
    }

    .grid-swipe .g-start-lg-7 {
        grid-column-start: 7
    }

    .grid-swipe .g-start-lg-8 {
        grid-column-start: 8
    }

    .grid-swipe .g-start-lg-9 {
        grid-column-start: 9
    }

    .grid-swipe .g-start-lg-10 {
        grid-column-start: 10
    }

    .grid-swipe .g-start-lg-11 {
        grid-column-start: 11
    }
}

@media (min-width: 1200px) {
    .mod-zone-similaire.cartes-3 .grid {
        grid-gap:140px
    }

    .mod-zone-similaire.cartes-4 .grid {
        grid-column-gap: 115px
    }

    .mod-zone-similaire.cartes-4 .grid .block-article:first-child .media {
        width: 360px
    }

    .mod-zone-similaire.cartes-4 .grid .block-article:first-child .content {
        max-width: 360px
    }

    .mod-zone-intro-commun .media {
        width: 456px
    }

    .block-article .content p {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden
    }

    .block-ressource-externe .content .description p {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden
    }

    .grid-swipe .g-col-xl-1 {
        grid-column: auto/span 1
    }

    .grid-swipe .g-col-xl-2 {
        grid-column: auto/span 2
    }

    .grid-swipe .g-col-xl-3 {
        grid-column: auto/span 3
    }

    .grid-swipe .g-col-xl-4 {
        grid-column: auto/span 4
    }

    .grid-swipe .g-col-xl-5 {
        grid-column: auto/span 5
    }

    .grid-swipe .g-col-xl-6 {
        grid-column: auto/span 6
    }

    .grid-swipe .g-col-xl-7 {
        grid-column: auto/span 7
    }

    .grid-swipe .g-col-xl-8 {
        grid-column: auto/span 8
    }

    .grid-swipe .g-col-xl-9 {
        grid-column: auto/span 9
    }

    .grid-swipe .g-col-xl-10 {
        grid-column: auto/span 10
    }

    .grid-swipe .g-col-xl-11 {
        grid-column: auto/span 11
    }

    .grid-swipe .g-col-xl-12 {
        grid-column: auto/span 12
    }

    .grid-swipe .g-start-xl-1 {
        grid-column-start: 1
    }

    .grid-swipe .g-start-xl-2 {
        grid-column-start: 2
    }

    .grid-swipe .g-start-xl-3 {
        grid-column-start: 3
    }

    .grid-swipe .g-start-xl-4 {
        grid-column-start: 4
    }

    .grid-swipe .g-start-xl-5 {
        grid-column-start: 5
    }

    .grid-swipe .g-start-xl-6 {
        grid-column-start: 6
    }

    .grid-swipe .g-start-xl-7 {
        grid-column-start: 7
    }

    .grid-swipe .g-start-xl-8 {
        grid-column-start: 8
    }

    .grid-swipe .g-start-xl-9 {
        grid-column-start: 9
    }

    .grid-swipe .g-start-xl-10 {
        grid-column-start: 10
    }

    .grid-swipe .g-start-xl-11 {
        grid-column-start: 11
    }
}
