// Header

.main__aside {
    position: fixed;
    z-index: 500;
    top: 50px;
    bottom: 30px;
    display: flex;
    flex-direction: column;
    background: #f4f4f4;
    box-shadow: 0 0 10px rgba(0,0,0,0.25);

    .menu-items {
        width: 50px;
        height: 100%;
        position: relative;
        z-index: 2;

        @media screen and (min-width: $screen-m) {
            width: 15vh;
        }

        .menu-items-list {
            list-style-type: none;
            padding: 0;
            margin: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .auxalentours-menuitem {
                color: #2c4452;
                cursor: pointer;
                padding: 0;
                display: flex;
                justify-content: center;
    
                .auxalentours-menuitem-content {
                    appearance: none;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 50px;
                    height: 60px;
                    padding: 0;
                    background-color: transparent;
                    outline: none;
                    box-shadow: none;
                    border: none;
                    color: #2c4452;
                    text-decoration: none;
                    cursor: pointer;

                    @media screen and (min-width: $screen-m) {
                        width: 15vh;
                        height: 12vh;
                    }

                    a {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        cursor: pointer;
                    }
    
                    img {
                        width: auto;
                        height: 4vh;
                        margin-bottom: 1vh;
                        filter: invert(21%) sepia(57%) saturate(291%) hue-rotate(165deg) brightness(35%) contrast(92%);
                    }
    
                    svg {
                        width: auto;
                        height: 4vh;
                        margin-bottom: 1vh;
                        fill: #2c4452;
                    }

                    i {
                        font-size: 1.5rem;

                        @media screen and (min-width: $screen-m) {
                            font-size: 2rem;
                        }
                    }
    
                    label {
                        text-align: center;
                        cursor: inherit;
                        font-size: 1.4vh;
                        text-transform: uppercase;
                        font-weight: 400;
                        margin: 0;
                        display: none;

                        @media screen and (min-width: $screen-m) {
                            display: block;
                        }
                    }
                }
    
                .active {
                    color: #C62222;
                    cursor: default;
                    position: relative;

                    &:before {
                        content:'';
                        display: block;
                        width: 0.5vh;
                        height: 100%;
                        background: #C62222;
                        position: absolute;
                        right: -0.5vh;
                    }

                    .auxalentours-menuitem-content {
                        color: #C62222;
                    }

                    img, svg {
                        // permet de passer en #2E404F
                        filter: brightness(0) saturate(100%) invert(18%) sepia(64%) saturate(3727%) hue-rotate(350deg) brightness(97%) contrast(89%);
                    }
                }
            }
        }
    }

    .info-rapport {
        position: absolute;
        bottom: 0;
    }
}